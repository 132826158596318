import LoginForm from "./login_components/login_form";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useLocation } from "react-router-dom";
import adminAuthorizationFunction from "../../view_models/admin_authorization/admin_authorization";
import ScreenLoadingSpinner from "../shared_components/screen_loading_spinner";

function LoginScreen() {
  const location = useLocation();
  let isQueryEnabled = true;
  if (location.state) {
    if (location.state.fromInitialScreen) {
      isQueryEnabled = false;
    }
  }
  const { isLoading, isSuccess, data } = useQuery({
    // don't retry and waste time if there was an authorization error cause it will not change
    retry: false,
    queryFn: adminAuthorizationFunction,
    queryKey: ["loginAdminAuthorization"],
    // only execute the query function if this page wasn't redirected from inital screen (home)
    enabled: isQueryEnabled,
  });

  if (location.state) {
    if (location.state.fromInitialScreen) {
      // if redirect came from initial screen no need to call authorize function again
      return (
        <section className="bg-theme-color-five h-screen flex justify-center items-center">
          <LoginForm />
        </section>
      );
    }
  } else {
    // if the user visited login screen from url /login
    if (isLoading) {
      return (
        <div className="bg-theme-color-five h-screen flex justify-center items-center">
          <ScreenLoadingSpinner />
        </div>
      );
    } else if (isSuccess) {
      // redirect user to home cause he cannot go to login page if he is already logged in
      return (
        <Navigate
          to="/"
          replace
          state={{ fromLoginScreen: true, adminEmail: data.email }}
        />
      );
    } else {
      // not authorized error
      return (
        <section className="bg-theme-color-five h-screen flex justify-center items-center">
          <LoginForm />
        </section>
      );
    }
  }
}

export default LoginScreen;
