import { useEffect, useRef } from "react";
import rahalLogo from "./../../../assets/images/Ra7al_logo.jpeg";
import { loginFunction } from "../../../view_models/login/login_slice";
import { useSelector, useDispatch } from "react-redux";
import LoginLoadingSpinner from "../../shared_components/loading_spinner";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../../view_models/login/login_slice";

function LoginForm() {
  const emailRef = useRef();
  const passwordRef = useRef();
  // state. (name of the reducer in the store)
  // useSelector is used to get the reducer state value
  const loginState = useSelector((state) => state.loginReducer.value);
  // use dispatch is used to call the action that the reducer is listening to
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function submitForm(event) {
    event.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    const loginData = {
      email: email,
      password: password,
      loggedFromWebsite: true,
    };
    dispatch(loginFunction(loginData));
  }

  // we want the useEffect to Rerender every time the login state changes to check if its success or not [loginState]
  // you must also add [navigate] cause it changes the state of the component
  // WE MUST BUT OUR NAVIGATION LOGIC INSIDE USE EFFECT because:
  // we are calling navigate() while rendering the Screen component.
  // The navigate() under the hood changes a state and that causes the issue
  useEffect(() => {
    if (loginState.isSuccess) {
      // navigate to home and pass the email
      // we must navigate from here cause we cannot navigate from outside react components
      navigate("/", {
        state: { fromLoginScreen: true, adminEmail: emailRef.current.value },
        replace: true,
      });
      // WE MUST RESET THE STATE BECAUSE USE EFFECT WILL BE CALLED EACH TIME THIS COMPONENT RENDERS WHEN
      // LOGIN SCREEN IS VISITED SO IT WILL KEEP NAVIGATING TO HOME PAGE EACH TIME WE GO TO LOGIN SCREEN
      // BECAUSE isSuccess WILL STAY = TRUE AND NEVER CHANGE DURING THE SESSION UNLESS WE RESET IT
      dispatch(resetState());
    }
  }, [loginState, navigate, dispatch]);

  return (
    <div className="bg-white rounded-lg">
      <form
        onSubmit={submitForm}
        className="flex flex-col p-28"
        autoComplete="on"
      >
        <img src={rahalLogo} alt="Rahal Logo" className="w-24 h-24 ml-[30%]" />
        <h2 className="text-2xl whitespace-nowrap">Welcome To Rahal</h2>
        <div className="py-2">
          <label htmlFor="email" className="block">
            Enter your email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            maxLength="255"
            required
            placeholder="Email"
            className="border border-gray-300 border-solid rounded w-full pl-1.5"
            ref={emailRef}
            autoComplete="on"
          />
        </div>
        <div className="py-2 pb-3">
          <label htmlFor="password" className="block">
            Enter your password:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            minLength="5"
            maxLength="255"
            required
            placeholder="Password"
            className="border border-gray-300 border-solid rounded w-full pl-1.5"
            ref={passwordRef}
          />
        </div>
        <button
          type="submit"
          className="bg-theme-color-four text-white font-bold border-1 rounded-md border-blue-300 border-solid
              text-lg"
        >
          Login
        </button>
        {loginState.isError ? (
          <p className="text-red-700">{loginState.errorMessage}</p>
        ) : null}
        {loginState.isLoading ? <LoginLoadingSpinner /> : null}
      </form>
    </div>
  );
}

export default LoginForm;
