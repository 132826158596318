import { Link, useLocation } from "react-router-dom";

function ChoiceScreen() {
  const location = useLocation();

  const chatId = new URLSearchParams(location.search).get("chatId");

  return (
    <section className=" h-screen flex justify-center items-center">
      <div className="bg-white rounded-lg py-10">
        <Link
          to={{
            pathname: "/shopper-details",
            search: "?chatId=" + chatId,
          }}
          className="bg-theme-color-two text-white font-bold border-1 rounded-md border-blue-300 border-solid
          p-1.5 text-base text-center mx-5  whitespace-nowrap "
        >
          Shopper Details
        </Link>
        <Link
          to={{
            pathname: "/rahal-details",
            search: "?chatId=" + chatId,
          }}
          className="bg-theme-color-two text-white font-bold border-1 rounded-md border-blue-300 border-solid
          p-1.5 text-base text-center mx-5  whitespace-nowrap "
        >
          Rahal Details
        </Link>
      </div>
    </section>
  );
}

export default ChoiceScreen;
