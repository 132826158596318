import { useSelector, useDispatch } from "react-redux";
import LoginLoadingSpinner from "../../shared_components/loading_spinner";
import { addAdminFunction } from "../../../view_models/add_admin/add_admin_slice";
import { useRef, useEffect } from "react";
import { resetState } from "../../../view_models/add_admin/add_admin_slice";

function AddAdminScreen() {
  const emailRef = useRef();

  // state. (name of the reducer in the store)
  // useSelector is used to get the reducer state value
  const addAdminState = useSelector((state) => state.addAdminReducer.value);

  const dispatch = useDispatch();

  function submitForm(event) {
    event.preventDefault();
    const email = emailRef.current.value;

    dispatch(addAdminFunction(email));
  }

  useEffect(() => {
    // we want to reset the state when the component is disposed to remove success or error messages
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(resetState());
    };
  }, [dispatch]);

  return (
    <section className=" h-screen flex justify-center items-center">
      <div className="bg-white rounded-lg">
        <form onSubmit={submitForm} className="p-16">
          <label htmlFor="email" className="block font-bold pb-2">
            Enter Admin Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            maxLength="255"
            required
            placeholder="Admin Email"
            className="border border-gray-300 border-solid rounded w-full pl-1.5 "
            ref={emailRef}
            autoComplete="on"
          />
          <button
            type="submit"
            className="bg-theme-color-two text-white font-bold border-1 rounded-md border-blue-300 border-solid
          p-1.5 mt-2  text-base"
          >
            Add admin
          </button>
          {addAdminState.isSuccess ? (
            <p className="text-green-700 font-bold pt-2">
              Admin added successfully
            </p>
          ) : null}
          {addAdminState.isError ? (
            <p className="text-red-700">{addAdminState.errorMessage}</p>
          ) : null}
          {addAdminState.isLoading ? <LoginLoadingSpinner /> : null}
        </form>
      </div>
    </section>
  );
}

export default AddAdminScreen;
