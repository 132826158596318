import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import LoginScreen from "../views/login/login_screen";
import PageNotFound from "../views/page_not_found_screen";
import HomeScreen from "../views/home/home_screen";
import AddAdminScreen from "../views/home/add_admin/add_admin_screen";
import SearchByOrderNumberScreen from "../views/home/search_by_order_number/search_by_order_number_screen";
import SearchByOrderIdScreen from "../views/home/search_by_order_id/search_by_order_id_screen";
import ChoiceScreen from "../views/home/rahal_or_shopper_choice/choice_screen";
import ShopperDetailsScreen from "../views/home/shopper_details/shopper_details_screen";
import RahalDetailsScreen from "../views/home/rahal_details/rahal_details_screen";
import PendingOrdersScreen from "../views/home/pending_orders/pending_orders_screen";

/*We need to protect the login screen and home screen because they can be accessed from url*/
/* 
WE HAVE DIVIDED THE PROTECTED ROUTES INTO 2 SECTIONS ACCORDING TO 2 DIFFERENT REQUIREMENTS
  ONE FOR LOGIN AND ONE FOR HOME ROUTES
1) LOGIN SECTION: IF AUTHORIZED REDIRECT TO HOME OTHERWISE CONTINUE TO LOGIN
2) HOME SECTION: IF AUTHORIZED CONTINUE HOME ROUTE OTHERWISE REDIRECT TO LOGIN
PROBLEM WITH THIS:
EVERY TIME THE USER GO TO INITIAL HOME SCREEN HE WILL BE CHECKED TWICE ONE AT INITIAL SCREEN AND ONE AT LOGIN
SOLUTION:
PASS VALUE FROM INITIAL PAGE TO HOME AND LOGIN AND DON'T CHECK USER AGAIN IF HE IS REDIRECTED FROM INITIAL PAGE
WHY WE DIVIDED INTO 2 STEPS:
BECAUSE IF WE MADE ONLY 1 STEP AT INITIAL SCREEN AND IF AUTHORIZED WE RETURN OUTLET THE USER WILL BE ALLOWED TO
GO TO THE LOGIN SCREEN IF HE IS AUTHORIZED AND WE DON'T WANT THE USER TO GO TO LOGIN SCREEN IF HE IS ALREADY LOGGED
*/

// NOTE: THE PARENT ROUTE IS CALLED ONLY ONCE AT FIRST AND CALLED AGAIN IF WE WENT TO ANOTHER PARENT ROUTE THEN WENT BACK TO IT
// THATS FOR SAME SESSION BUT IF WE VISITED ANY URL INSIDE THE PARENT ROOT IT WILL BE CALLED EACH TIME WE VISIT IT FROM URL
const myRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/" element={<HomeScreen />}>
        {/*The add admin screen is the first screen that we want it to appear when we visit the home route*/}
        <Route index element={<AddAdminScreen />} />
        <Route
          path="search-by-order-number"
          element={<SearchByOrderNumberScreen />}
        />
        <Route path="search-by-order-id" element={<SearchByOrderIdScreen />} />
        <Route path="pending-orders" element={<PendingOrdersScreen />} />
        <Route path="details-choice" element={<ChoiceScreen />} />
        <Route path="shopper-details" element={<ShopperDetailsScreen />} />
        <Route path="rahal-details" element={<RahalDetailsScreen />} />
      </Route>
      {/*for any other path (*) we want to display 404 page not found screen*/}
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default myRouter;
