import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./login/login_slice";
import logoutReducer from "./logout/logout_slice";
import addAdminReducer from "./add_admin/add_admin_slice";
import shopperStepFourReducer from "./shopper_step_four/shopper_step_four_slice";
import sendOtpReducer from "./send_otp/send_otp_slice";
import shopperStepFiveReducer from "./shopper_step_five/shopper_step_five_slice";
import rahalStepThreeReducer from "./rahal_step_three/rahal_step_three_slice";

export const store = configureStore({
  reducer: {
    loginReducer: loginReducer,
    logoutReducer: logoutReducer,
    addAdminReducer: addAdminReducer,
    shopperStepFourReducer: shopperStepFourReducer,
    sendOtpReducer: sendOtpReducer,
    shopperStepFiveReducer: shopperStepFiveReducer,
    rahalStepThreeReducer: rahalStepThreeReducer,
  },
});
