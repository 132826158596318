import clsx from "clsx";

function DefaultSpinner(props) {
  const marginClass = props.marginClass;
  const classes = clsx(
    marginClass,
    "border-t-blue-600",
    "border-blue-300",
    "border-4",
    "rounded-full",
    "animate-spin",
    "h-8 w-8",
    "mx-5",
    "border-gray-300"
  );

  return <div className={classes} />;
}

export default DefaultSpinner;
