import { serverUrl } from "../../core/server_constants";
import axios from "axios";

// USE REACT QUERY NOT REDUX CAUSE WE ARE FETCHING STATE BEFORE RENDERING!
async function adminAuthorizationFunction() {
  const response = await axios.get(serverUrl + "/user/authorize-admin-access", {
    // this option prevent axios from throwing any erros related to status codes returned from server
    validateStatus: () => true,
  });
  const responseData = response.data;
  // if no error is thrown then it will be success
  if (responseData.error) {
    throw new Error(responseData.error);
  }

  // YOU MUST RETURN DATA FROM QUERY FUNCTION IN CASE OF SUCCESS IT CANNOT BE UNDEFINED
  return responseData.adminData;
}
export default adminAuthorizationFunction;
