import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverUrl } from "../../core/server_constants";
import axios from "axios";

export const logoutFunction = createAsyncThunk(
  "logoutSlice/login",
  async () => {
    const response = await axios.delete(
      serverUrl + "/user/admin-website-logout",
      {
        // this option prevent axios from throwing any erros related to status codes returned from server
        validateStatus: () => true,
      }
    );
    const responseData = response.data;
    // if no error is thrown then log out will be success (fulfilled)
    if (responseData.error) {
      throw new Error(responseData.error);
    }
  }
);

const initialStateValue = {
  isSuccess: false,
  isError: false,
  errorMessage: "",
  isLoading: false,
};

const logoutSlice = createSlice({
  name: "logoutSlice",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    // YOU MUST RESET THE STATE BECAUSE EVERY TIME THE USER GO TO HOME SCREEN IT RERENDER
    // THEREFORE USE EFFECT WILL BE CALLED AND STATE WILL STILL BE SUCCESS SO USER WILL BE
    // REDIRECTED TO LOG OUT SCREEN AGAIN CAUSE THE STATE WON'T CHANGE UNLESS WE RESET IT
    resetState: (state, action) => {
      state.value = initialStateValue;
    },
  },
  // USE REACT WHEN FETCHING DATA AND USE REDUX OTHERWISE
  // working with async state-> like async value in riverpod
  extraReducers: (builder) => {
    // the payload is the returned value not the argument
    builder
      // loading state
      .addCase(logoutFunction.pending, (state, action) => {
        state.value = { ...state, isLoading: true };
      })
      // when the async state is fullfilled means when the waiting is finished and data is returned
      .addCase(logoutFunction.fulfilled, (state, action) => {
        state.value = {
          ...state,
          isLoading: false,
          isSuccess: true,
        };
      })
      // error state when an error is thrown
      .addCase(logoutFunction.rejected, (state, action) => {
        const errorMessage = action.error.message;
        state.value = {
          ...state,
          isLoading: false,
          isError: true,
          errorMessage: errorMessage,
        };
      });
  },
});

export default logoutSlice.reducer;

export const { resetState } = logoutSlice.actions;
