import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverUrl } from "../../core/server_constants";
import axios from "axios";

export const shopperStepFourFunction = createAsyncThunk(
  "shopperStepFourSlice/shopperStepFour",
  async (bodyData) => {
    const response = await axios.put(
      serverUrl + "/order-tracking/shopper-step-four",
      {
        shopperId: bodyData.shopperId,
        chatId: bodyData.chatId,
        offerId: bodyData.offerId,
      },
      {
        // this option prevent axios from throwing any erros related to status codes returned from server
        validateStatus: () => true,
      }
    );
    const responseData = response.data;
    // if no error is thrown then add admin will be success (fulfilled)
    if (responseData.error) {
      throw new Error(responseData.error);
    }
  }
);

const initialStateValue = {
  isSuccess: false,
  isError: false,
  errorMessage: "",
  isLoading: false,
};

const shopperStepFourSlice = createSlice({
  name: "shopperStepFourSlice",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    resetState: (state, action) => {
      state.value = initialStateValue;
    },
  },
  // USE REACT WHEN FETCHING DATA AND USE REDUX OTHERWISE
  // working with async state-> like async value in riverpod
  extraReducers: (builder) => {
    // the payload is the returned value not the argument
    builder
      // loading state
      .addCase(shopperStepFourFunction.pending, (state, action) => {
        state.value = { ...state, isLoading: true };
      })
      // when the async state is fullfilled means when the waiting is finished and data is returned
      .addCase(shopperStepFourFunction.fulfilled, (state, action) => {
        state.value = {
          ...state,
          isLoading: false,
          isSuccess: true,
        };
      })
      // error state when an error is thrown
      .addCase(shopperStepFourFunction.rejected, (state, action) => {
        const errorMessage = action.error.message;
        state.value = {
          ...state,
          isLoading: false,
          isError: true,
          errorMessage: errorMessage,
        };
      });
  },
});

export default shopperStepFourSlice.reducer;

export const { resetState } = shopperStepFourSlice.actions;
