import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import getShopperOrderDetailsFunction from "../../../view_models/get_shopper_order_details/get_shopper_order_details";
import CenterLoadingSpinner from "../../shared_components/center_spinner";
import { publicImagesServerUrl } from "../../../core/server_constants";
import clsx from "clsx";
import { shopperStepFourFunction } from "../../../view_models/shopper_step_four/shopper_step_four_slice";
import { useSelector, useDispatch } from "react-redux";
import DefaultSpinner from "../../shared_components/default_spinner";
import { useEffect, useRef } from "react";
import { resetState as stepFourResetState } from "../../../view_models/shopper_step_four/shopper_step_four_slice";
import { resetState as resetSendOtpState } from "../../../view_models/send_otp/send_otp_slice";
import { resetState as stepFiveResetState } from "../../../view_models/shopper_step_five/shopper_step_five_slice";
import { sendOtpFunction } from "../../../view_models/send_otp/send_otp_slice";
import { shopperStepFiveFunction } from "../../../view_models/shopper_step_five/shopper_step_five_slice";

function ShopperDetailsScreen() {
  const location = useLocation();
  const chatId = new URLSearchParams(location.search).get("chatId");
  const intChatId = parseInt(chatId);
  const queryClient = useQueryClient();

  const { isSuccess, data, isFetching, error } = useQuery({
    queryFn: () => getShopperOrderDetailsFunction(chatId),
    queryKey: ["getShopperDetails", chatId],
  });

  const shopperStepFourState = useSelector(
    (state) => state.shopperStepFourReducer.value
  );
  const sendOtpState = useSelector((state) => state.sendOtpReducer.value);
  const shopperStepFiveState = useSelector(
    (state) => state.shopperStepFiveReducer.value
  );
  const dispatch = useDispatch();
  const bottomRef = useRef();
  const otpRef = useRef();

  useEffect(() => {
    // we want to reset the state when the component is disposed to remove success or error messages
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(stepFourResetState());
      dispatch(resetSendOtpState());
      dispatch(stepFiveResetState());
    };
  }, [dispatch]);

  if (isSuccess) {
    const shopperId = data.shopperOrderDetails.shopperDetails.user_id;
    const offerId = data.shopperOrderDetails.shopperDetails.accepted_offer_id;

    const isWaitingForStorageDelivery =
      data.shopperOrderDetails.shopperDetails.shopper_order_step === 3
        ? true
        : false;
    const isWaitingForClientDelivery =
      data.shopperOrderDetails.shopperDetails.shopper_order_step === 4
        ? true
        : false;
    const finishedStepFour =
      data.shopperOrderDetails.shopperDetails.shopper_order_step > 3
        ? true
        : false;
    const finishedStepFive =
      data.shopperOrderDetails.shopperDetails.shopper_order_step > 4
        ? true
        : false;

    const enabledButtonClasses = clsx(
      "bg-theme-color-two",
      "text-white",
      "font-bold",
      "border-1",
      "rounded-md",
      "border-blue-300",
      "border-solid",
      "p-1.5",
      "text-base",
      "text-center",
      "mx-5",
      "whitespace-nowrap"
    );
    const disabledButtonClasses = clsx(
      "bg-gray-400",
      "text-gray-200",
      "opacity-80",
      "font-bold",
      "border-1",
      "rounded-md",
      "border-blue-300",
      "border-solid",
      "p-1.5",
      "text-base",
      "text-center",
      "mx-5",
      "whitespace-nowrap"
    );

    function deliveredToStorageButtonClicked() {
      // you can only pass one argument to async thunk functions
      dispatch(
        shopperStepFourFunction({
          shopperId: shopperId,
          chatId: intChatId,
          offerId: offerId,
        })
      );
    }

    function sendOtp() {
      dispatch(
        sendOtpFunction({
          chatId: intChatId,
          receiverId: shopperId,
          userType: "Shopper",
        })
      );
    }

    function otpFormSubmitted(event) {
      event.preventDefault();

      dispatch(
        shopperStepFiveFunction({
          shopperId: shopperId,
          chatId: intChatId,
          offerId: offerId,
          otp: otpRef.current.value,
          userType: "Shopper",
        })
      );
    }

    if (shopperStepFourState.isSuccess || shopperStepFiveState.isSuccess) {
      // invalidate the query to get the new step
      // you must use queryKey: attribute when invalidating by key
      queryClient.invalidateQueries({
        queryKey: ["getShopperDetails", chatId],
      });
    }

    if (sendOtpState.isSuccess) {
      // scroll to the bottom to view the otp field
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    return (
      <>
        <div className=" w-screen absolute top-1/4 font-bold">
          <div className=" bg-theme-color-two  text-center">
            <p className="text-lg text-white">
              {data.shopperOrderDetails.shopperDetails.first_name +
                " " +
                data.shopperOrderDetails.shopperDetails.last_name}
            </p>
            <p className="text-base text-gray-400">
              {data.shopperOrderDetails.shopperDetails.email}
            </p>
          </div>
          <div className="bg-white text-left p-5 pt-10">
            {data.shopperOrderDetails.shopperMessages.map((message) => {
              return message.is_image === 0 &&
                message.sender_id === shopperId ? (
                <div key={message.message_id} className="block">
                  <span className="inline-block bg-theme-color-five p-5 mb-5 rounded-md">
                    <p>{message.content}</p>
                  </span>
                </div>
              ) : message.is_image === 1 && message.sender_id === shopperId ? (
                <div key={message.message_id} className="block">
                  <span className="inline-block bg-theme-color-five p-5 mb-5 rounded-md">
                    <img
                      src={publicImagesServerUrl + "/" + message.content}
                      className="w-3/5 h-full "
                      alt="checkout "
                    />
                  </span>
                </div>
              ) : null;
            })}
          </div>
          <div className="text-center py-5">
            <button
              onClick={deliveredToStorageButtonClicked}
              className={
                isWaitingForStorageDelivery
                  ? enabledButtonClasses
                  : disabledButtonClasses
              }
              disabled={isWaitingForStorageDelivery ? false : true}
            >
              {finishedStepFour ? "Done" : "Order Delivered To Storage"}
            </button>
            <button
              onClick={sendOtp}
              className={
                isWaitingForClientDelivery
                  ? enabledButtonClasses
                  : disabledButtonClasses
              }
              disabled={isWaitingForClientDelivery ? false : true}
            >
              {finishedStepFive ? "Done" : "Order Delivered To Client"}
            </button>
            {sendOtpState.isSuccess ? (
              <form className="mt-4" onSubmit={otpFormSubmitted}>
                <input
                  type="text"
                  minLength="5"
                  maxLength="5"
                  required
                  placeholder="Enter OTP Code"
                  className="rounded pl-1"
                  ref={otpRef}
                />
                <button
                  type="submit"
                  className={enabledButtonClasses + " text-xs bg-green-500"}
                >
                  Submit Code
                </button>
              </form>
            ) : null}
            {shopperStepFourState.isError ? (
              <p className="text-red-700">
                {shopperStepFourState.errorMessage}
              </p>
            ) : null}
            {shopperStepFiveState.isError ? (
              <p className="text-red-700">
                {shopperStepFiveState.errorMessage}
              </p>
            ) : null}
            {sendOtpState.isError ? (
              <p className="text-red-700">{sendOtpState.errorMessage}</p>
            ) : null}
            {shopperStepFourState.isLoading ? (
              <DefaultSpinner marginClass="ml-[41vw]" />
            ) : null}

            {sendOtpState.isLoading || shopperStepFiveState.isLoading ? (
              <DefaultSpinner marginClass="ml-[58vw]" />
            ) : null}
          </div>
          <div ref={bottomRef}></div>
        </div>
      </>
    );
  } else if (isFetching) {
    return <CenterLoadingSpinner />;
  } else {
    // in case of error
    return (
      <div className="h-screen flex justify-center items-center">
        <p className="text-red-700 whitespace-nowrap">{error.message}</p>
      </div>
    );
  }
}

export default ShopperDetailsScreen;
