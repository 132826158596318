import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverUrl } from "../../core/server_constants";
import axios from "axios";

export const sendOtpFunction = createAsyncThunk(
  "sendOtpSlice/sendOtp",
  async (bodyData) => {
    const response = await axios.post(
      serverUrl + "/user/send-otp",
      {
        chatId: bodyData.chatId,
        receiverId: bodyData.receiverId,
        userType: bodyData.userType,
      },
      {
        // this option prevent axios from throwing any erros related to status codes returned from server
        validateStatus: () => true,
      }
    );
    const responseData = response.data;
    // if no error is thrown then add admin will be success (fulfilled)
    if (responseData.error) {
      throw new Error(responseData.error);
    }
  }
);

const initialStateValue = {
  isSuccess: false,
  isError: false,
  errorMessage: "",
  isLoading: false,
};

const sendOtpSlice = createSlice({
  name: "sendOtpSlice",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    resetState: (state, action) => {
      state.value = initialStateValue;
    },
  },
  // USE REACT WHEN FETCHING DATA AND USE REDUX OTHERWISE
  // working with async state-> like async value in riverpod
  extraReducers: (builder) => {
    // the payload is the returned value not the argument
    builder
      // loading state
      .addCase(sendOtpFunction.pending, (state, action) => {
        state.value = { ...state, isLoading: true };
      })
      // when the async state is fullfilled means when the waiting is finished and data is returned
      .addCase(sendOtpFunction.fulfilled, (state, action) => {
        state.value = {
          ...state,
          isLoading: false,
          isSuccess: true,
        };
      })
      // error state when an error is thrown
      .addCase(sendOtpFunction.rejected, (state, action) => {
        const errorMessage = action.error.message;
        state.value = {
          ...state,
          isLoading: false,
          isError: true,
          errorMessage: errorMessage,
        };
      });
  },
});

export default sendOtpSlice.reducer;

export const { resetState } = sendOtpSlice.actions;
