import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import adminAuthorizationFunction from "../../view_models/admin_authorization/admin_authorization";
import ScreenLoadingSpinner from "../shared_components/screen_loading_spinner";
import Navbar from "../shared_components/navbar";

// Outlet is a placeholder for the children routes of the home route
function HomeScreen() {
  const location = useLocation();
  let isQueryEnabled = true;
  // if the redirect came from the login screen it means the admin has been already verified so go directly to home
  if (location.state) {
    if (location.state.fromLoginScreen) {
      isQueryEnabled = false;
    }
  }
  const { isLoading, isSuccess, data } = useQuery({
    // don't retry and waste time if there was an authorization error cause it will not change
    retry: false,
    queryFn: adminAuthorizationFunction,
    queryKey: ["homeAdminAuthorization"],
    // only execute the query function if this page wasn't redirected from inital screen (home)
    enabled: isQueryEnabled,
  });
  if (location.state) {
    if (location.state.fromLoginScreen) {
      // if this page is redirected from login screen
      return (
        <>
          <Navbar
            adminEmail={location.state.adminEmail}
            currentPath={location.pathname}
          />
          <Outlet />
        </>
      );
    }
  } else {
    // if the user visited login screen from url /login
    if (isLoading) {
      return (
        <div className="bg-theme-color-five h-screen flex justify-center items-center">
          <ScreenLoadingSpinner />
        </div>
      );
    } else if (isSuccess) {
      return (
        <>
          <Navbar adminEmail={data.email} currentPath={location.pathname} />
          <Outlet />
        </>
      );
    } else {
      // not authorized error
      return (
        <Navigate to="/login" replace state={{ fromInitialScreen: true }} />
      );
    }
  }
}

export default HomeScreen;
