import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoutLoadingSpinner from "./logout_spinner";
import { resetState } from "../../view_models/logout/logout_slice";
import { logoutFunction } from "../../view_models/logout/logout_slice";
import { useEffect } from "react";

function LogoutButton() {
  const logoutState = useSelector((state) => state.logoutReducer.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function logoutUser() {
    dispatch(logoutFunction());
  }
  // if error
  if (logoutState.isError) {
    alert(logoutState.errorMessage);
  }
  // if success
  useEffect(() => {
    if (logoutState.isSuccess) {
      // we must navigate from here cause we cannot navigate from outside react components
      navigate("/login", {
        state: { fromInitialScreen: true },
        replace: true,
      });
      // reset the state cause if the user logged in again he will keep being redirected to login page
      // because the logout state will stay success and when logout button renders it will navigate user to login page
      // this will happen in same session when no browser refresh occurs
      dispatch(resetState());
    }
  }, [logoutState, navigate, dispatch]);

  return (
    <button onClick={logoutUser} type="button" className="pr-5">
      {logoutState.isLoading ? <LogoutLoadingSpinner /> : null} Log Out
    </button>
  );
}

export default LogoutButton;
