import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import searchByOrderNumberFunction from "../../../view_models/search_by_order_number/search_by_order_number";
import ChatsLoadingSpinner from "../../shared_components/chats_spinner";
import { publicImagesServerUrl } from "../../../core/server_constants";
import { Link } from "react-router-dom";

function SearchByOrderNumberScreen() {
  // since the value will be used in useQuery you must use useState not useRef
  const [orderNumber, setOrderNumber] = useState("");

  /*
  The isFetching property is true whenever a query is being fetched,
   regardless of whether it is the initial fetch or a refetch. 
  This means that isFetching will be true even if there is already cached data for the query.
  */
  const { refetch, isSuccess, data, isFetching, isError, error } = useQuery({
    retry: false,
    queryFn: () => searchByOrderNumberFunction(orderNumber),
    queryKey: ["searchByOrderNumber", orderNumber],
    enabled: false, // disable this query from automatically running
  });

  function submitForm(event) {
    event.preventDefault();
    // manually refetch
    refetch();
  }

  return (
    <section className="flex flex-col ">
      <form
        className="flex justify-center items-start mt-52 self-center"
        onSubmit={submitForm}
      >
        <input
          type="text"
          name="orderNumber"
          maxLength="255"
          required
          placeholder="Enter order number"
          className="border border-gray-300 border-solid rounded w-full pl-1.5 "
          autoComplete="on"
          onChange={(event) => {
            setOrderNumber(event.target.value);
          }}
        />
        <button
          type="submit"
          className="bg-theme-color-two text-white font-bold border-1 rounded-md border-blue-300 border-solid
          p-1.5 text-xs text-center ml-2 mr-2  whitespace-nowrap "
        >
          Search orders
        </button>
        {isError ? (
          <p className="text-red-700 whitespace-nowrap">{error.message}</p>
        ) : null}
      </form>
      {isFetching ? <ChatsLoadingSpinner /> : null}
      {isSuccess ? (
        data.length === 0 ? (
          <p className="text-red-700 whitespace-nowrap self-center">
            No Results Found
          </p>
        ) : (
          data.map((chatData) => {
            return (
              <div
                key={chatData.chat_id}
                className="flex justify-between bg-white m-10 rounded-md"
              >
                <img
                  src={publicImagesServerUrl + "/" + chatData.chat_image}
                  className="w-2/12 h-full "
                  alt="Order"
                />
                <p className="text-lg font-bold self-center">
                  {chatData.chat_name}
                </p>
                <Link
                  to={{
                    pathname: "/details-choice",
                    search: "?chatId=" + chatData.chat_id,
                  }}
                  className="bg-theme-color-two text-white font-bold border-1 rounded-md border-blue-300 border-solid
          p-1.5 m-2 text-base text-center mr-2 whitespace-nowrap self-center"
                >
                  View Details
                </Link>
              </div>
            );
          })
        )
      ) : null}
    </section>
  );
}

export default SearchByOrderNumberScreen;
