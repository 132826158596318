import { useQuery } from "@tanstack/react-query";
import getPendingOrdersChatsFunction from "../../../view_models/get_pending_orders/get_pending_orders_chats";
import { publicImagesServerUrl } from "../../../core/server_constants";
import { Link } from "react-router-dom";
import CenterLoadingSpinner from "../../shared_components/center_spinner";

function PendingOrdersScreen() {
  /*
  The isFetching property is true whenever a query is being fetched,
   regardless of whether it is the initial fetch or a refetch. 
  This means that isFetching will be true even if there is already cached data for the query.
  */
  const { isSuccess, data, isFetching, isError, error } = useQuery({
    retry: false,
    queryFn: getPendingOrdersChatsFunction,
    queryKey: ["getPendingOrdersChats"],
    enabled: true, // disable this query from automatically running
  });

  return (
    <section className="flex flex-col justify-center">
      {isError ? (
        <>
          <div className="h-screen flex justify-center items-center">
            <p className="text-red-700 whitespace-nowrap">{error.message}</p>
          </div>
        </>
      ) : null}

      {isFetching ? <CenterLoadingSpinner /> : null}
      {isSuccess ? (
        data.length === 0 ? (
          <p className="text-red-700 whitespace-nowrap self-center">
            No Results Found
          </p>
        ) : (
          <div className="mt-40">
            {data.map((chatData) => {
              return (
                <div
                  key={chatData.chat_id}
                  className="flex justify-between bg-white mt-10 rounded-md"
                >
                  <img
                    src={publicImagesServerUrl + "/" + chatData.chat_image}
                    className="w-2/12 h-full "
                    alt="Order"
                  />
                  <p className="text-lg font-bold self-center">
                    {chatData.chat_name}
                  </p>
                  <Link
                    to={{
                      pathname: "/details-choice",
                      search: "?chatId=" + chatData.chat_id,
                    }}
                    className="bg-theme-color-two text-white font-bold border-1 rounded-md border-blue-300 border-solid
          p-1.5 m-2 text-base text-center mr-2 whitespace-nowrap self-center"
                  >
                    View Details
                  </Link>
                </div>
              );
            })}
          </div>
        )
      ) : null}
    </section>
  );
}

export default PendingOrdersScreen;
