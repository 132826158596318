import { RouterProvider } from "react-router-dom";
import myRouter from "./routing/react_router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  // refetshOnWindowFocus: false to prevent refetshing data when visiting tap again while its open
  const queryClient = new QueryClient({
    defaultOptions: {
      // these options will be applied to all queries
      queries: {
        refetchOnWindowFocus: false,
        // staleTime:Infinity , this will prevent query from refetching the data when state changes and component rerender
        // we set disable this behavior because we will invalidate it anyway when we want it to refetch otherwise its useless
        staleTime: Infinity,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={myRouter} />
      </QueryClientProvider>
    </>
  );
}

export default App;
