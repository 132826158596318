import { NavLink } from "react-router-dom";
import rahalNoBgLogo from "./../../assets/images/Ra7al_logo_no_bg.png";
import LogoutButton from "./logout_button";
import clsx from "clsx";

function Navbar(props) {
  const activeLinkClasses = clsx("bg-theme-color-one", "p-2.5", "rounded-md");
  const currentPath = props.currentPath;

  return (
    <nav className="bg-theme-color-five fixed w-screen flex items-center justify-between flex-wrap font-bold rounded-sm">
      <img src={rahalNoBgLogo} alt="Rahal logo" className="w-24 h-24" />
      <NavLink className={currentPath === "/" ? activeLinkClasses : ""} to="/">
        Add Admin
      </NavLink>
      <NavLink
        className={
          currentPath === "/search-by-order-number" ? activeLinkClasses : ""
        }
        to="/search-by-order-number"
      >
        Search By Order Number
      </NavLink>
      <NavLink
        className={
          currentPath === "/search-by-order-id" ? activeLinkClasses : ""
        }
        to="/search-by-order-id"
      >
        Search By Order Id
      </NavLink>
      <NavLink
        className={currentPath === "/pending-orders" ? activeLinkClasses : ""}
        to="/pending-orders"
      >
        Pending Orders
      </NavLink>
      <p className="text-gray-500">{props.adminEmail}</p>
      <LogoutButton />
    </nav>
  );
}

export default Navbar;
